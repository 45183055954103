import React, { FC, Fragment } from 'react';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';

type TSearchModal = {
  open?: boolean;
  children?: React.ReactNode;
  onClose: () => void;
};

const SearchModal = ({ children, open, onClose }: TSearchModal) => {
  return (
    <Transition appear show={open}>
      <Dialog
        className={`fixed inset-0 z-50 overflow-x-hidden overflow-y-auto`}
        onClose={onClose}
      >
        <TransitionChild
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 cursor-pointer bg-brand-dark/70"
            aria-hidden="true"
            onClick={onClose}
          />
        </TransitionChild>

        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-110"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-110"
        >
          <div className="relative z-50 w-full">
            <div className="relative">
              <button
                onClick={onClose}
                aria-label="Close panel"
                className="absolute opacity-1 right-6 top-2"
              >
                Cancel
              </button>

              {children}
            </div>
          </div>
        </TransitionChild>
      </Dialog>
    </Transition>
  );
};

export default SearchModal;
