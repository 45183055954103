'use client';
import Script from 'next/script';
import React from 'react';

export const HeaderScript = ({ lang }: { lang: string }) => {

    if (process.env.NODE_ENV === 'development') {
        return null;
    }


  // hide the zendesk widget for specific page
  let hideZendesk = false;
  let klaviyoId = 'TDYEVx';

  if (typeof window !== 'undefined') {
    if (
      window.location.pathname === '/contacts' ||
      window.location.pathname.includes('/store-admin')
    ) {
      hideZendesk = true;
    }
  }

  if (lang === 'my') {
    hideZendesk = true;
    klaviyoId = 'RSHTeW';
  }

  return (
    <>
      <Script src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoId}`} />
      {!hideZendesk && (
        <Script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=1f96c06d-9e1f-4166-b689-782eb024d56e"
          onReady={() => {
            if (typeof window !== 'undefined') {
              // check if zendesk is already loaded
              if (window.zE) {
                setTimeout(() => {
                  const el = document.querySelector(
                    '#launcher',
                  ) as HTMLIFrameElement;

                  if (el) {
                    el.classList.add('!bottom-14');
                  }
                }, 1500);
              }
            }
          }}
        />
      )}
    </>
  );
};
