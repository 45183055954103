'use client';
import Script from 'next/script';
import React, { useEffect } from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

export const FooterScript = ({ lang }: { lang: string }) => {

    if (process.env.NODE_ENV === 'development') {
        return null;
    }

  const [hideWhatsapp, setHideWhatsapp] = React.useState(true);
  const [hideMouseflow, setHideMouseflow] = React.useState(false);

  useEffect(() => {

        if (lang === 'sg') {            
            setHideWhatsapp(true);
        }

        if (lang === 'my') {
            setHideWhatsapp(false);
        }

        if (typeof window !== undefined) {
            if (
            window.location.pathname === '/contacts' ||
            window.location.pathname.includes('/store-admin')
            ) {
                setHideWhatsapp(true);            
            }

            if (window.location.pathname.includes('/store-admin')) {
                setHideMouseflow(true);
            }
        }

    }, [lang]);
  

  return (
    <>
      {!hideMouseflow &&  (  
      <Script
        src="//cdn.mouseflow.com/projects/d88e5246-9440-40bd-9532-00fbad16646c.js"
        onReady={() => {
          window._mfq = window._mfq || [];
        }}
      />
    )}

      {!hideWhatsapp && (
        <FloatingWhatsApp
          phoneNumber="601133282895"
          accountName="Michael Trio"
        />
      )}
    </>
  );
};
