export const setUrl = (
  url: string,
  lang?: string,
  query?: Record<string, string>,
) => {
  const websiteUrl = process.env['NEXT_PUBLIC_WEBSITE_URL'];
  let baseUrl = '';
  const langUrl = lang ? `/${lang}` : '';

  // check if the url is an external url
  if (url.indexOf('http') > -1) {
    return url;
  }

  if (
    baseUrl.indexOf('michaeltrio.com') > 0 ||
    baseUrl.indexOf('localhost') > 0
  ) {
    baseUrl = websiteUrl;
  }

  if (url.charAt(0) !== '/') {
    url = '/' + url;
  }

  if (query && query !== undefined) {
    const params = new URLSearchParams(query);
    return baseUrl + url + '?' + params.toString();
  }

  return langUrl + baseUrl + url;
};

/**
 * url path
 */

export const getURLPath = (url: string) => {
  let paths = url.split('/');

  if (url.charAt(0) == '/') {
    paths = paths.slice(1);
  }

  return paths;
};

export const setCurrentPathname = (path: string, basePath?: string) => {
  const currentUrl = new URL(window.location.href);

  if (basePath) {
    return `${basePath}${path}${currentUrl.search}`;
  }

  return `${path}${currentUrl.search}`;
};