'use client';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';

type TProps = {
  popupId: string;
}

export const KlaviyoPopup = ({ popupId }: TProps) => {

  useEffect(() => {

    if (typeof window !== undefined) {
      if (
        window.location.pathname === '/contacts' ||
        window.location.pathname.includes('/store-admin') ||
        window.location.pathname.includes('/checkout') ||
        window.location.pathname.includes('/newsletter')
      ) {
        return
      }
    }

    if (!Cookies.get('klaviyo_popup')) {
      setTimeout(() => {
        
        window._klOnsite = window._klOnsite || [];
        window._klOnsite.push(['openForm', popupId]);

        Cookies.set('klaviyo_popup', popupId, { expires: 1, sameSite: 'strict', secure: true, path: '/' });
        
      }, 2000);
    }

  }, []);

  return <></>;
};
