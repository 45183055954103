'use client';

import Modal from '@components/common/modal/modal';
import SearchModal from '@components/search/search-modal';
import dynamic from 'next/dynamic';
import { useModalStore } from '@components/common/modal/modal.context';

const LoginForm = dynamic(() => import('@components/auth/login-form'));
const SignUpForm = dynamic(() => import('@components/auth/sign-up-form'));
const ForgetPasswordForm = dynamic(
  () => import('@components/auth/forget-password-form'),
);
const ProductPopup = dynamic(() => import('@components/product/product-popup'));
const AddressPopup = dynamic(
  () => import('@components/common/form/address-form'),
);
const PaymentPopup = dynamic(
  () => import('@components/common/form/add-payment'),
);
const PhoneNumberPopup = dynamic(
  () => import('@components/common/form/add-contact'),
);
const DeliveryAddresses = dynamic(
  () => import('@components/address/delivery-addresses'),
);
const ShopShow = dynamic(
  () => import('@components/collection-point/shop-view'),
);

const CategoryPopup = dynamic(
  () => import('@components/category/category-popup'),
);

const SearchAutomcomplete = dynamic(
  () => import('@components/search/search-autocomplete'),
);

type TModal = {
  lang: string;
};

export default function ManagedModal({ lang }: TModal) {
  const { closeModal, isOpen, view, data } = useModalStore();

  if (view === 'CATEGORY_VIEW') {
    return (
      <Modal open={isOpen} onClose={() => closeModal()} variant="bottom">
        {view === 'CATEGORY_VIEW' && <div>test</div>}
      </Modal>
    );
  }

  if (view === 'SEARCH_VIEW') {
    return (
      <SearchModal open={isOpen} onClose={() => closeModal()}>
        <SearchAutomcomplete lang={lang} initialSearch={data} />
      </SearchModal>
    );
  }

  return (
    <Modal open={isOpen} onClose={() => closeModal()}>
      {view === 'LOGIN_VIEW' && <LoginForm lang={lang} />}
      {view === 'SIGN_UP_VIEW' && <SignUpForm lang={lang} />}
      {view === 'FORGET_PASSWORD' && (
        <ForgetPasswordForm lang={lang} isModal={true} />
      )}
      {view === 'PRODUCT_VIEW' && <ProductPopup lang={lang} />}
      {view === 'ADDRESS_VIEW_AND_EDIT' && <AddressPopup lang={lang} />}
      {view === 'PAYMENT' && <PaymentPopup lang={lang} />}
      {view === 'PHONE_NUMBER' && <PhoneNumberPopup lang={lang} />}
      {view === 'DELIVERY_VIEW' && <DeliveryAddresses lang={lang} />}
      {view === 'SHOP_VIEW' && <ShopShow lang={lang} />}
    </Modal>
  );
}
