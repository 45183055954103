'use client'
import { usePathname } from "next/navigation";
import { useEffect } from "react";

export default function ScrollRestoration() {
    const pathname = usePathname();

    useEffect(() => {
        window.onpopstate = function (event: PopStateEvent) {
            if (event.state) {
                setTimeout(() => {
                    const scrollPos = sessionStorage.getItem('__jwl_scrollPos');
                    if (scrollPos) {
                        window.scrollTo({ behavior: 'smooth', top: parseInt(scrollPos) });                 
                        sessionStorage.removeItem('__jwl_scrollPos');
                    }            
                }, 1000);
            }  
        };
    }, [pathname]);

    return null;
}
