'use client';

import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SessionProvider } from 'next-auth/react';

function Providers({ children }: React.PropsWithChildren) {  

    const queryClient = new QueryClient();

    return (
        <SessionProvider>
            <QueryClientProvider client={queryClient}>
                {children}        
            </QueryClientProvider>
        </SessionProvider>
    );
}

export default Providers;
